@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'inter-ui/inter.css';

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  animation: gradientAnimation 5s ease infinite;
  background-size: 300% 300%;
}

.custom-cursor {
  cursor: url('./icons/cursor.svg'), auto;
}